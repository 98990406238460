import { StaticImage } from 'gatsby-plugin-image';
import { Observer } from 'mobx-react-lite';
import React from 'react';

type MapTexturedBackgroundProps = {}

const MapTexturedBackground: React.FC<MapTexturedBackgroundProps> = props => {
  return <Observer children={() => (
    <StaticImage src="../../images/map/map-textured-bg.png"
      style={{
        position: 'absolute',
        // width: '2000px',
        // height: '2000px',
        top: -50,
        bottom: -50,
        right: -50,
        left: -50,
      }}
      imgStyle={{
        background: `#EEE7DB`,
        backgroundBlendMode: 'multiply',
        backgroundRepeat: 'round',
        mixBlendMode: 'normal'
      }}
      alt="Map Textured Background"
    />
  )} />
}

export default MapTexturedBackground;