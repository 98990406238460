import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../utils/mobx.utils';
import { wineriesData } from './wineries';
import './PageSectionEscapeToMarlboroughMap.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import WishlistButton from '../WishlistButton/WishlistButton'
import { action } from 'mobx';
import { useControllers } from '../../controllers/app.controller';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import EscapeToMarlboroughMap from './EscapeToMarlboroughMap';
import WineryCard from '../WineryCard/WineryCard';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import MapTexturedBackground from './MapTexturedBackground';
import { GatsbyFile } from '../../types/gatsby.types';
import useAllWpProduct from '../../content/AllWpProduct/AllWpProduct';

type PageSectionEscapeToMarlboroughMapProps = {}

const PageSectionEscapeToMarlboroughMap: React.FC<PageSectionEscapeToMarlboroughMapProps> = props => {

  const { wineryImages } = useStaticQuery(graphql`
    {
      wineryImages: allFile(
        filter: {absolutePath: {regex: "/images/map/wineries/"}}
      ) {
        nodes {
          id
          name
          ext
          childImageSharp {
            id
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    }
  `);
  const products = useAllWpProduct();

  const { UI } = useControllers();
  const mapWrapperRef = useObservableRef<HTMLDivElement>();
  const s = useStore(() => ({
    get products() { return products.filter(p => p.categories.nodes.some(c => c.slug === 'winery')) },
    getProductByName(name: string) {
      return s.products.find(p => p.title === name);
    },
    get wineryFilenameToGatsbyImageData() {
      const gatsbyImages: GatsbyFile[] = wineryImages.nodes;
      return gatsbyImages.reduce((obj, item) => ({ ...obj, [`${item.name}${item.ext}`]: item.childImageSharp.gatsbyImageData }), {}) as { [k in string]: IGatsbyImageData };
    },
    getGatsbyImage: (filename: string) => {
      return s.wineryFilenameToGatsbyImageData[filename];
    },
    isSliderDragging: false,
    isSliderReady: false,
    slider: null as null | Slider,
    selectedWineryId: wineriesData[0].id,
    get selectedWinery() { return wineriesData.find(w => w.id === s.selectedWineryId) },
    get selectedWinerySlideIndex() { return wineriesData.findIndex(w => w.id === s.selectedWineryId) },
    get wineriesData() {
      const paddedData = []
      for (let i = 0; i < s.slidesToShow; i++) {
        paddedData.push({});
      }
      // @ts-ignore
      return wineriesData.concat(paddedData);
    },
    onClickPoint: action((id: number) => {
      if (!id || s.isSliderDragging) return;
      s.selectedWineryId = id;
      // console.log("🚀 ~ file: PageSectionEscapeToMarlboroughMap.tsx ~ line 41 ~ onClickPoint:action ~ s.selectedWinery", s.selectedWinery)
      if (s.selectedWinerySlideIndex !== -1) s.slider?.slickGoTo(s.selectedWinerySlideIndex, true); // dontAnimate set to true because its animation tend to animate to blank content.
    }),
    onClickSlide: action((id: number) => {
      if (!id || s.isSliderDragging) return;
      s.selectedWineryId = id;
      // console.log("🚀 ~ file: PageSectionEscapeToMarlboroughMap.tsx ~ line 41 ~ onClickPoint:action ~ s.selectedWinery", s.selectedWinery)
    }),
    onSlidePrevOrNext: action((slideIndex: number) => {
      if (UI.uptoDesktopMd) {
        s.selectedWineryId = wineriesData[slideIndex]?.id ?? wineriesData[wineriesData.length - 1].id;
      }
    }),
    setDragging: action(() => {
      s.isSliderDragging = true;
    }),
    setNotDragging: action(() => {
      s.isSliderDragging = false;
    }),
    get slidesToShow() {
      switch (true) {
        // case UI.onlyPhones:
        // return 1;
        case UI.uptoDesktopMd:
          return 1;
        case UI.fromDesktopXl:
          return 8;
        case UI.fromDesktopLg:
          return 7;
        case UI.fromDesktopMd:
          return 6;
        case UI.fromDesktop:
        default:
          return 4;
      }
    },
    get sliderSettings() {
      return {
        className: 'Slider slider variable-width',
        infinite: false,
        slidesToShow: s.slidesToShow,
        speed: 100,
        centerMode: true,
        focusOnSelect: true,
        // variableWidth: true,
        initialSlide: 0,
        swipeToSlide: true,
        touchThreshold: 100,
        onInit: action(() => { s.isSliderReady = true; }),
        // prevArrow: <SliderArrow type='prev' />,
        // nextArrow: <SliderArrow type='next' />,
        afterChange: action((curr: any) => {
          s.setNotDragging();
          s.onSlidePrevOrNext(curr);
        }),
        // slide: 'span', // doesn't seem to change anything.
      }
    },
    isCollapse: true,
    wineryIdToExpand: null as number | null,
    handleToggleWineryCardInfo: (wineryId: number) => (preventDefault?: boolean) => action((e?: React.SyntheticEvent<HTMLElement | SVGElement, Event>) => {
      if (preventDefault) {
        e?.preventDefault();
        e?.stopPropagation();
      }
      if (s.wineryIdToExpand === wineryId) {
        s.isCollapse = !s.isCollapse;
      } else {
        s.isCollapse = false;
        s.wineryIdToExpand = wineryId;
      }
    })
  }))
  return <Observer children={() => (
    <div className="PageSectionEscapeToMarlboroughMap">

      <MapTexturedBackground />

      {UI.onlyPhones && <div className="ContentMapHeaderMobile">
        <h1>Explore Marlborough's<br /> Best Wineries</h1>
        <p>Marlborough’s first<br /> interactive wineries map since 2014</p>
      </div>}

      <div className="ContentMapWrapper content_top_maps" id="winery-maps">
        <div className="ContentMap content_maps_top_img_absolute" ref={mapWrapperRef}>
          <EscapeToMarlboroughMap mapWrapperRef={mapWrapperRef} selectedWinery={s.selectedWinery} onClickPoint={s.onClickPoint} />
          {!UI.onlyPhones && <div className="ContentMapHeader">
            <h1>Explore Marlborough's<br /> Best Wineries</h1>
            <p>Marlborough’s first<br /> interactive wineries map since 2014</p>
          </div>}
          {s.selectedWinery && <div className="ContentMapWineryInfo">
            {s.getGatsbyImage(s.selectedWinery.image) && <GatsbyImage
              className="ContentMapWineryInfoImage"
              image={s.getGatsbyImage(s.selectedWinery.image)}
              alt={s.selectedWinery.name}
              objectFit="contain"
            />}
            <h2>{s.selectedWinery.name}</h2>
            <p>{s.selectedWinery.desc}</p>
            <WishlistButton alwaysShowText product={s.getProductByName(s.selectedWinery.name)} />
          </div>}
        </div>
      </div>

      <div className="SliderWrapper">
        <Slider
          {...s.sliderSettings}
          ref={slider => (s.slider = slider)}
          onSwipe={s.setDragging}
        >
          {s.isSliderReady && s.wineriesData.map((w, idx) => (

            <div key={`${w.id}-${idx}`} className="SlideWrapper">
              {w.id && <WineryCard
                winery={w}
                wineryAsWpProduct={s.getProductByName(w.name)}
                selectedWineryId={s.selectedWineryId}
                wineryIdToExpand={s.wineryIdToExpand}
                isCollapse={s.isCollapse}
                onClickSlide={() => s.onClickSlide(w.id)}
                handleToggleWineryCardInfo={s.handleToggleWineryCardInfo(w.id)}
                gatsbyImage={s.getGatsbyImage(w.image)}
              />}
            </div>

          ))}
        </Slider>
      </div>

    </div>
  )} />
}

export default PageSectionEscapeToMarlboroughMap;