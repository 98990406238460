import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { WpProduct } from '../../types/wordpress.types';
import joinClassNames from '../../utils/className.utils';
import { useProps } from '../../utils/mobx.utils';
import BaseIcon from '../BaseIcon/BaseIcon';
import { WineryMapPointData } from '../PageSectionEscapeToMarlboroughMap/wineries';
import WishlistButton from '../WishlistButton/WishlistButton';
import './WineryCard.scss';

type WineryCardProps = {
  winery: WineryMapPointData,
  wineryAsWpProduct: WpProduct | undefined,
  selectedWineryId: number,
  wineryIdToExpand: number | null,
  gatsbyImage: IGatsbyImageData,
  isCollapse: boolean,
  onClickSlide: () => void;
  handleToggleWineryCardInfo: (preventDefault?: boolean) => () => void;
}

const WineryCard: React.FC<WineryCardProps> = props => {
  const p = useProps(props);
  return <Observer children={() => (
    <div className={joinClassNames("WineryCardWrapper", p.wineryIdToExpand === p.selectedWineryId && p.selectedWineryId === p.winery.id && !p.isCollapse ? 'expand' : '')}>
      <div
        className={joinClassNames(`WineryCard slider_logo_img slider_logo_img${p.winery.id} slider_logo_img_wineries canvas_maps${p.winery.id} animalcontentSelector`, p.selectedWineryId === p.winery.id ? "isSelected" : "")}
        onClick={p.onClickSlide}
        data-id={p.winery.id}
      >
        <div className="WineryCardInner" onClick={p.handleToggleWineryCardInfo()}>
          {/* {p.winery.image && <img className="WineryLogo" title={p.winery.name} src={`/map/wineries/${p.winery.image}`} />} */}
          {p.gatsbyImage && <GatsbyImage
            className="WineryLogoWrapper"
            imgClassName="WineryLogo"
            image={p.gatsbyImage}
            alt={p.winery.name}
            objectFit="contain"
          />}

          <div className="WineryCardInfo">
            <h1>{p.winery.name}</h1>
            <p>{p.winery.desc}</p>
            {p.wineryAsWpProduct && <span><WishlistButton product={p.wineryAsWpProduct} alwaysShowText asDiv /></span>}
          </div>
        </div>

        <div className='WineryCardControls'>
          {p.wineryAsWpProduct && <span><WishlistButton product={p.wineryAsWpProduct} doNotShowLabel asDiv /></span>}
          <div onClick={p.handleToggleWineryCardInfo(true)} ><BaseIcon className={joinClassNames('BaseIconCollapse')} icon="collapse" /></div>
        </div>

        <div className="WineryCardStandaloneWishlistBtn">
          {p.wineryAsWpProduct && <span><WishlistButton product={p.wineryAsWpProduct} doNotShowLabel asDiv /></span>}
        </div>
      </div>
    </div>
  )} />
}

export default WineryCard;