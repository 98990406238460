import React from 'react';
import { Observer } from 'mobx-react-lite';
import { useControllers } from '../../controllers/app.controller';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { ObservableRef, useObservableRef } from '../../hooks/useObservableRef.hook';
import { fastIntDivision } from '../../utils/math.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { wineriesData, WineryMapPointData } from './wineries';
import './EscapeToMarlboroughMap.scss';
import joinClassNames from '../../utils/className.utils';
import { StaticImage } from 'gatsby-plugin-image';
import MapTexturedBackground from './MapTexturedBackground';

// Map points dataset
// Replace this dataset with real data
// Unfortunately the locations of all points will need to be recalculated
const mapExtrasPointsData = [
  {
    'id': 0,
    'x': 1145,
    'y': 532,
    'width': 90,
    'height': 35,
    'imgsrc': '/map/indicators/blenheim.png',
  },
  {
    'id': 1,
    'x': 625,
    'y': 500,
    'width': 80,
    'height': 30,
    'imgsrc': '/map/indicators/renwick.png',
  },
  {
    'id': 2,
    'name': 'RAPAURA RD',
    'x': 950,
    'y': 240,
    'width': 110,
    'height': 60,
    'imgsrc': '/map/indicators/rapaura-rd.png',
  },
  {
    'id': 3,
    'name': 'MAPSCALE',
    'x': 1580,
    'y': 730,
    'width': 111,
    'height': 64,
    'imgsrc': '/map/indicators/map-scale.png',
  },
  {
    id: 4,
    name: 'Circle Background',
    x: -145,
    y: 400,
    width: 400,
    height: 400,
    imgsrc: '/map/map-circle-bg.png',
  }
];
// 'x': 970,
// 'y': 730,
// 'width': 200,
// 'height': 100,
// ori
// 'x': 1570,
// 'y': 730,
// 'width': 111,
// 'height': 64,

// 'x': 310,
// 'y': 660,
// 'x': 300,
// 'y': 750,
const mapExtrasTOPCData = [
  {
    'id': 4,
    'name': 'PICTON',
    'info': '30 MIN FROM BLENHEIM',
    'x': 1185,
    'y': 50,
    'width': 14,
    'height': 14,
    'imgsrc': '/map/indicators/arrow-up-right.png',
  },
  {
    'id': 5,
    'name': 'CHRISTCHURCH',
    'info': 'Temporary Acess, 6h25m - 7 Hr',
    'x': 170,
    'y': 650,
    'width': 14,
    'height': 14,
    'imgsrc': '/map/indicators/arrow-down-left.png',
  },
  {
    'id': 7,
    'name': 'CHRISTCHURCH (CLOSED)',
    'info': 'State Highway 1, not open at present',
    'x': 1480,
    'y': 710,
    'width': 14,
    'height': 14,
    'imgsrc': '/map/indicators/arrow-down-right.png',
  },
];

const mapExtrasAirportData = [
  {
    'id': 6,
    'name': 'Airport',
    'x': 805,
    'y': 535,
    'width': 14,
    'height': 14,
    'imgsrc': '/map/indicators/airport-star.png',
    'type': 'airport'
  },
];


// Use D3.js to create the SVG container & add basic elements & containers

// var svgMap = d3.select('.content_maps_top_img_absolute')
//             .append('svg')
//             .attr('class','map-svg-container')
//             .attr('width', 1913)
//             .attr('viewBox','0 0 1913 830');

type EscapeToMarlboroughMapProps = {
  onClickPoint: (id: number) => void,
  selectedWinery: WineryMapPointData | undefined,
  mapWrapperRef: ObservableRef<HTMLElement> | undefined,
}

const EscapeToMarlboroughMap: React.FC<EscapeToMarlboroughMapProps> = props => {

  const { UI } = useControllers();
  const mapSVGRef = useObservableRef<SVGSVGElement>();

  const p = useProps(props);
  const s = useStore(() => ({
    get inlineMapStyles() {
      if (!p.selectedWinery || !UI.uptoDesktopMd) return {};
      const mapWrapper = p.mapWrapperRef?.current;
      const mapSVG = mapSVGRef.current; // not used.
      if (!mapWrapper || !mapSVG) return {};

      // Center winery position on svg map.
      // Requires (1) user's viewport, and (2) winery svg coords on svg map.
      // NOTE: This assumes svg image is of its original size, i.e. the image width/height has not been css tampered with. If so, the calculation below will need to take those measurements into consideration.
      const { x: wineryX, y: wineryY } = p.selectedWinery;
      const translatedX = wineryX - fastIntDivision(mapWrapper.clientWidth, 2);
      const translatedY = wineryY - fastIntDivision(mapWrapper.clientHeight, 2);
      const cssX = translatedX * -1;
      const cssY = translatedY * -1;
      return {
        transition: 'transform 0.5s ease-in-out 0s',
        transform: `translateX(${cssX}px) translateY(${cssY}px)`
      }
    }
  }))
  useOnMount(() => {
    addBackgroundToMapPoints();
  })

  const addBackgroundToMapPoints = () => {
    const mapPointsData = wineriesData;
    for (let i = 0; i < mapPointsData.length; ++i) {
      // Selecting the corresponding map point's label and get to it's bounding box
      var keyId = mapPointsData[i].id;
      var mapPointId = 'map-point-' + keyId;
      var mapPointLabel = document.getElementById(mapPointId)?.childNodes[3];
      // @ts-ignore
      var mapPointBBox = mapPointLabel?.getBBox();

      // get the size and coordinates of the bounding box and storing them in to the data array
      var mapPointLabelWidth = mapPointBBox.width;
      var mapPointLabelHeight = mapPointBBox.height;
      var mapPointLabelX = mapPointBBox.x;
      var mapPointLabelY = mapPointBBox.y;
      if (!mapPointsData[i]) return;
      mapPointsData[i].labelWidth = mapPointLabelWidth;
      mapPointsData[i].labelHeight = mapPointLabelHeight;
      mapPointsData[i].labelX = mapPointLabelX;
      mapPointsData[i].labelY = mapPointLabelY;

      // defining the dimensions and coordinates of the text label background rectangles with the data from previous step.
      // The + 10 and - 6 creates a padding.
      var mapPointlabelBGSelector = '#' + mapPointId + ' ' + '.map-point-label-bg';
      const bg = document.querySelector(mapPointlabelBGSelector);
      if (!bg) return;
      bg.setAttribute('width', mapPointLabelWidth + 10);
      bg.setAttribute('height', mapPointLabelHeight + 3);
      bg.setAttribute('x', mapPointLabelX - 5 + "");
      bg.setAttribute('y', mapPointLabelY - 3 + "");
    }
  }

  return <Observer children={() => (
    <svg className="map-svg-container" width="1913" viewBox="0 0 1913 830" ref={mapSVGRef} style={s.inlineMapStyles}>
      <title>Wineries in Blenheim</title>

      <g className="map-background">
        <foreignObject width="1913" height="830">
          <MapTexturedBackground />
          <StaticImage width={1913} height={830} src="../../images/map/map-wide.png" placeholder="none" style={{ mixBlendMode: 'multiply' }} alt="Wineries in Blenheim map" />
        </foreignObject>
      </g>

      <g className='map-extras-group'>
        {mapExtrasPointsData.map((pd) => <g
          key={`extras-point-${pd.id}`}
          id={`extras-point-${pd.id}`}
          className='mapExtrasPoints'
        >
          <image x={pd.x} y={pd.y} width={pd.width} height={pd.height} href={pd.imgsrc} className='map-extras-point-img' />
        </g>)}
      </g>

      <g className='map-extras-group2'>
        {mapExtrasTOPCData.map((pd) => <g
          key={`extras-point-${pd.id}`}
          id={`extras-point-${pd.id}`}
          className='mapExtrasPoints'
        >
          <image x={pd.x} y={pd.y} width={pd.width} height={pd.height} href={pd.imgsrc} className='map-extras-topc-img' />
          <text
            x={pd.name === 'CHRISTCHURCH' ? (pd.x + pd.width * 2) : (pd.x - pd.width)}
            y={pd.name === 'CHRISTCHURCH' ? (pd.y + (pd.height * 0.2)) : (pd.y + (pd.height * 0.2))}
            style={{
              textAnchor: pd.name === 'CHRISTCHURCH' ? 'start' : 'end',
              fontWeight: 900,
              fill: 'firebrick',
            }}
            className='map-extras-topc-text'
          >
            TO {pd.name}
          </text>
          <text
            x={pd.name === 'CHRISTCHURCH' ? (pd.x + pd.width * 2) : (pd.x - pd.width)}
            y={pd.name === 'CHRISTCHURCH' ? (pd.y + (pd.height * 1.4)) : (pd.y + (pd.height * 1.4))}
            style={{
              textAnchor: pd.name === 'CHRISTCHURCH' ? 'start' : 'end',
              fontWeight: 700,
              fill: 'firebrick',
            }}
            className='map-extras-topc-info'
          >
            {pd.info}
          </text>
        </g>)}
      </g>

      <g className='map-extras-group3'>
        {mapExtrasAirportData.map((pd) => <g
          key={`extras-point-${pd.id}`}
          id={`extras-point-${pd.id}`}
          className='mapExtrasPoints'
        >
          <image x={pd.x} y={pd.y} width={pd.width} height={pd.height} href={pd.imgsrc} className='map-extras-airport-img' />
          <text
            x={(pd.x + (pd.width * 2.5))}
            y={(pd.y + (pd.height * 2.2))}
            style={{
              textAnchor: pd.name === 'CHRISTCHURCH' ? 'start' : 'end',
              fontWeight: 700,
              fill: 'firebrick',
            }}
            className='map-extras-airport-text'
          >
            {pd.name}
          </text>
        </g>)}
      </g>

      <g className='map-points-group'>
        {wineriesData.map((pd) => <g
          key={`map-point-${pd.id}`}
          id={`map-point-${pd.id}`}
          className={joinClassNames(`map-point label-${pd.label_direction} ${pd.type}`, p.selectedWinery?.id === pd.id ? "isSelected" : "")}
          onClick={() => p.onClickPoint(pd.id)}
        >
          <rect x={pd.x} y={pd.y} width='10px' height='10px' transform={`rotate(45 ${pd.x} ${pd.y})`} className='map-point-diamond' />
          <image x={pd.x} y={pd.y} width='14px' height='14px' href={pd.imgsrc} className='map-point-arrow' />

          <rect className='map-point-label-bg' />

          <text
            x={pd.label_direction === 'left' ? pd.x - 15 : pd.x + 15}
            y={pd.y + 13}
          >
            {pd.name}
          </text>
        </g>)}
      </g>
    </svg>
  )} />
}

export default EscapeToMarlboroughMap;