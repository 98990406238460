export type Winery = {
  id: number,
  name: string,
  left_pos: string,
  top_pos: string,
  styleclass: string,
  description: string,
  image: string,
  on_map: string,
  label: string,
  created_at: string,
  updated_at: string,
}

export const wineries: Winery[] = [
  {
    id: 1,
    name: "Allan Scott",
    left_pos: "845",
    top_pos: "368",
    styleclass: "img_maps",
    description: "Allan Scott has planted some of the region’s most famous vineyards, including Marlborough’s very first. He has been working there every vintage since. As one of the first independent wineries, Allan Scott Family Winemakers have made a name for themselves in this highly competitive industry.",
    image: "01.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 2,
    name: "Auntsfield",
    left_pos: "848",
    top_pos: "715",
    styleclass: "img_maps",
    description: "David Herd has become Marlborough's founding winemaker after planting the first grapes in 1873. Hidden away on the vineyard is Herd's original wine cellar a place where history is still alive in the land, and in the wine.",
    image: "03.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 3,
    name: "Bladen",
    left_pos: "560",
    top_pos: "425",
    styleclass: "img_maps_right",
    description: "Bladen's cellar door is probably the smallest in the region. Stable doors open from the small tasting room to a trellised courtyard area with Cape Cod chairs in the shade of an Almond tree. Chris or Dave will provide a friendly welcome to every visitor.",
    image: "04.png",
    on_map: "1",
    label: "0",
    created_at: "2015-10-15 21:01:00",
    updated_at: "2015-10-15 21:01:00"
  },
  {
    id: 4,
    name: "Brancott Estate",
    left_pos: "795",
    top_pos: "635",
    styleclass: "img_maps_right",
    description: "Brancott Estate Heritage Centre is located on an elevated position overlooking the original Brancott Vineyard. The location celebrates the home of the original Marlborough Sauvignon Blanc plantings - a varietal that has gone on to become the world’s favourite New Zealand wine style.",
    image: "06.png",
    on_map: "1",
    label: "0",
    created_at: "2015-10-15 21:06:00",
    updated_at: "2015-10-15 21:06:00"
  },
  {
    id: 5,
    name: "Clos Henri",
    left_pos: "406",
    top_pos: "552",
    styleclass: "img_maps",
    description: "Clos Henri has been certified organic since the harvest of 2013 and is nestled under the southern foothills of Marlborugh's Wairau Valley. It is the wine estate meticulously established and organically run by the famous Sancerre wine growing family of Henri Bourgeois (France).",
    image: "07.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 6,
    name: "Cloudy Bay",
    left_pos: "874",
    top_pos: "387",
    styleclass: "img_maps",
    description: "The vineyards of Cloudy Bay are located at superior sites throughout the Marlborough region to maximise the considerable diversity that exists in micro-climates and soil types. The different sites provide nuances of aromas and flavours, bringing complexity to the wines.",
    image: "08.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 7,
    name: "Framingham",
    left_pos: "580",
    top_pos: "387",
    styleclass: "img_maps",
    description: "Framingham houses the oldest Riesling vines in Marlborough which was planted in 1981. They developed the F series which gives them the freedom to push boundaries with wild fermentation, new varieties and new techniques.",
    image: "12.png",
    on_map: "1",
    label: "0",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 8,
    name: "Forrest",
    left_pos: "629",
    top_pos: "423",
    styleclass: "img_maps",
    description: "Vintage 1990 saw the first Forrest wine and immediate success with a trophy at the national wine awards - success that has been repeated many times both nationally and internationally over the subsequent vintages.",
    image: "13.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 9,
    name: "Fromm",
    left_pos: "767",
    top_pos: "529",
    styleclass: "img_maps_right",
    description: "Known for its European style and Kiwi ingenuity, Fromm Wineries produces an array of outstanding wines. Swiss winemaker Georg Fromm and friend Hatsch Kalberer, who had experience in both the traditional ways of European winemaking and the more modern kiwi ways, opened their doors in 1992.",
    image: "14.png",
    on_map: "1",
    label: "0",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 10,
    name: "Gibson Bridge",
    left_pos: "639",
    top_pos: "470",
    styleclass: "img_maps_right",
    description: "Gibson Bridge is a boutique family owned vineyard located in Renwick, the heart of Marlborough. Howard and Julie Simmonds planted the block with Pinot Gris as a mainstay but also with tiny quantities of Syrah, Malbec, Merlot, Pinot Noir and Gewurztraminer.",
    image: "16.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 11,
    name: "Giesen",
    left_pos: "631",
    top_pos: "379",
    styleclass: "img_maps_right",
    description: "Giesen wines was founded by Alex, Theo and Marcel, three German brothers who had “hobby vines” since an early age. The Giesen Wines team believes it’s your enjoyment of their wines that truly brings their passion for winemaking to life.",
    image: "17.png",
    on_map: "1",
    label: "0",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 12,
    name: "Hans Herzog",
    left_pos: "713",
    top_pos: "303",
    styleclass: "img_maps",
    description: "With less than 3000 cases per year Hans Herzog truly believes in quality over quantity. It was mid-90s when Hans Herzog first planted the Pinot Gris, Viognier and Montepulciano grapes, varieties rarely witnessed in NZ at the time and have managed to lead the way with new varieties while still maintaining the quality.",
    image: "18.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 13,
    name: "Highfield",
    left_pos: "705",
    top_pos: "602",
    styleclass: "img_maps",
    description: "Highfield Winery started of with 2.5 acres of Muller Thurgau, a German grape variety. It was by accident that they discovered some “rogue” grapes amongst the Muller Thurgau vines, which proved to be none other than Sauvignon Blanc. With the discovery of the Sauvignon Blanc grapes the Highfield Winery label was born.",
    image: "19.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 14,
    name: "Huia",
    left_pos: "645",
    top_pos: "351",
    styleclass: "img_maps",
    description: "In their early years Claire and Mike gained valuable experience in wineries in Marlborough at Cloudy Bay, Vavasour and Lawson’s Dry Hills. This great grounding and appreciation of the sense of land, heritage and tradition from the Old World plays a big part in their success.",
    image: "20.png",
    on_map: "1",
    label: "0",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 15,
    name: "Hunter’s",
    left_pos: "893",
    top_pos: "304",
    styleclass: "img_maps",
    description: "Hunter’s Wines have won more than 165 gold medals, both national and international. Jane is an internationally respected vintner and recipient of the prestigious UK Women in Wine Award and has an OBE.",
    image: "21.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 16,
    name: "Johanneshof Cellars",
    left_pos: "1260",
    top_pos: "50",
    styleclass: "img_maps_up",
    description: "Johanneshof Cellars is not only known for its multi award-winning wines but also for some outstanding spirits such as Brandy and Grappa. With a good dose of Kiwi ingenuity they’ve combined old world knowledge and customs with new world terroir and technology.",
    image: "22.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 17,
    name: "Lawson’s Dry Hills",
    left_pos: "1260",
    top_pos: "602",
    styleclass: "img_maps",
    description: "The original company vineyard was planted in 1980 and the Lawsons’s Dry Hills label launched about twelve years later. Their use of modern winemaking techniques and carefully selected sites providing various characteristics from the different soils and microclimates results in wines with an authentic expression.",
    image: "24.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 18,
    name: "Mount Riley",
    left_pos: "1418",
    top_pos: "583",
    styleclass: "img_maps",
    description: "New Zealand’s first Sparkling Sauvignon Blanc was carefully crafted by Mount Riley Winery. With four distinct labels Mount Riley makes wine that people love because of its spirit, attitude and authenticity. Down to earth and approachable, just like themselves.",
    image: "26.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 19,
    name: "Nautilus",
    left_pos: "620",
    top_pos: "376",
    styleclass: "img_maps_right",
    description: "Nautilus cellar door has been rated in the top 10 cellar doors in Marlborough for several years and received a “gold award” for excellent customer service. The stunning Nautilus shell sculpture that has become a local landmark has been designed and made by Whanganui artist Dale Hudson.",
    image: "27.png",
    on_map: "1",
    label: "0",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 20,
    name: "No.1 Family Estate",
    left_pos: "701",
    top_pos: "353",
    styleclass: "img_maps_right",
    description: "No 1. Family Estate is completely dedicated to Methode Traditionelle wines. They only make one type of wine and do it exceedingly well. With all the equipment imported from Champagne this French Heritage is owned by the Le Brun family, a champagne-making family over the last 12 generations.",
    image: "28.png",
    on_map: "1",
    label: "0",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 21,
    name: "Omaka Springs",
    left_pos: "625",
    top_pos: "663",
    styleclass: "img_maps_right",
    description: "Omaka Springs Estates is the sole producer of estate wines in the Omaka Valley. Omaka Springs does not only produce beautiful pinot noirs but is also known for their olive oil which is hand bottled on site.",
    image: "29.png",
    on_map: "1",
    label: "0",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 22,
    name: "Rock Ferry",
    left_pos: "1039",
    top_pos: "391",
    styleclass: "img_maps",
    description: "Rock Ferry Wines grows outstanding grapes in the Marlborough region and beyond. With vineyards in Bendigo and Central Otago they might seem audacious, but challenging is exactly the way they like it.",
    image: "31.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 23,
    name: "Saint Clair",
    left_pos: "1063",
    top_pos: "223",
    styleclass: "img_maps",
    description: "Saint Clair has developed six vineyards in different sites in Marlborough. Only superior fruit from the best locations for each individual variety is selected. In 2001 they introduced a ‘grading system’ for all it’s vineyards different wine batches and marks them out of 10 for quality each year.",
    image: "32.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 24,
    name: "Seresin Estate",
    left_pos: "523",
    top_pos: "499",
    styleclass: "img_maps_right",
    description: "The land of Seresin Estate is not only planted with a large range of white grapes and Pinot Noir but also houses olive groves, orchards and vegetable gardens. Organically and biodynamically grown, hand-tended and hand-picked, the grapes from their estate are transformed into artisan wines.",
    image: "33.png",
    on_map: "1",
    label: "0",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 25,
    name: "Spy Valley",
    left_pos: "534",
    top_pos: "588",
    styleclass: "img_maps",
    description: "Situated up the road from a US government listening post, Spy Valley produces wine that have been called “impossible to ignore”. Not only does this vineyard have beautiful wines and oils to offer, but is also an award winning architecturally designed winery on its own.",
    image: "34.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 26,
    name: "Ta Whare Ra",
    left_pos: "615",
    top_pos: "516",
    styleclass: "img_maps_right",
    description: "Due to all the small tanks Te Whare Ra Wines installed, the winery is able to keep each vineyard parcel (clone, soil type, vine age, etcetera) separate. This means they can experiment with lots of different techniques to only bottle the best.",
    image: "36.png",
    on_map: "1",
    label: "0",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 27,
    name: "Villa Maria",
    left_pos: "868",
    top_pos: "624",
    styleclass: "img_maps",
    description: "George Fistonich received the country’s first knighthood for his services to the NZ wine industry. At the age of 21, he started Villa Maria as a one-man band. Today Villa Maria employs more than 250 permanent staff and export wine to over 50 countries worldwide.",
    image: "37.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 28,
    name: "Wairau River",
    left_pos: "626",
    top_pos: "367",
    styleclass: "img_maps_right",
    description: "Wairau River is a real family estate. Every aspect of producing and selling the wine rests in the hand of a family member. With nine estate vineyards of around 700 acres, Wairau River Wines can maintain total commitment to quality by carefully managing the sites.",
    image: "39.png",
    on_map: "1",
    label: "0",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 29,
    name: "Wither Hills",
    left_pos: "1070",
    top_pos: "621",
    styleclass: "img_maps",
    description: "The Winery and Cellar door are located in the heart of the Wairau Valley, looking out to the Wither Hills Ranges. As of 2010, about 40.8ha of the Wither Hills Vineyard is managed organically and they continually improve their environmental performance.",
    image: "40.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 30,
    name: "Yealands",
    left_pos: "1418",
    top_pos: "774",
    styleclass: "img_maps_down",
    description: "With seven vineyards, Yealands owns the single largest privately owned vineyard in New Zealand. Yealands vineyards are not only planted with the usual Marlborough varieties, but also with international grapes such as Gruner Veltiner and Tempranillo.",
    image: "41.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 31,
    name: "Moa Brewery",
    left_pos: "870",
    top_pos: "351",
    styleclass: "img_maps",
    description: "The Moa Brewing Company was founded in 2003 by Josh Scott, the son of renowned Marlborough winemaker Allan Scott, with a focus on brewing super premium handcrafted beers. The traditional technique Moa uses naturally carbonates the beer and gives the beer an elegant, champagne-like mouthfeel.",
    image: "42.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 32,
    name: "Renaissance Brewing Co.",
    left_pos: "1213",
    top_pos: "459",
    styleclass: "img_maps",
    description: "Committed to using local ingredients, the award-winning craft beer producer Renaissance Brewing produceks top end, ultra premium ales that nicely showcase New Zealand hops and malt in a range of British, American and European styles.",
    image: "43.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:26",
    updated_at: "2018-01-09 21:29:26"
  },
  {
    id: 33,
    name: "Whitehaven",
    left_pos: "714",
    top_pos: "348",
    styleclass: "img_maps_up",
    description: "Whitehaven Wines is a family-owned business in the heart of Marlborough. Whitehaven's small, passionate team creates wines of quality, intensity and consistency, in the pursuit of 'quality without compromise'.",
    image: "44.png",
    on_map: "1",
    label: "1",
    created_at: "2015-10-29 02:12:50",
    updated_at: "2015-10-29 02:12:50"
  },
  {
    id: 34,
    name: "Escape to Picton",
    left_pos: "1260",
    top_pos: "20",
    styleclass: "img_maps_up",
    description: "Juliearna Kavanagh, ex-owner of award winning establishment Kavanagh House, has won many awards for her creations. At Escape to Picton,  you are sure to be delivered the highest quality dining experience Picton has to offer.",
    image: "45.png",
    on_map: "1",
    label: "1",
    created_at: "2016-09-23 14:19:20",
    updated_at: "2016-09-23 14:19:20"
  },
  {
    id: 35,
    name: "Jackson Estate",
    left_pos: "868",
    top_pos: "481",
    styleclass: "img_maps",
    description: "The Homestead vineyard, Jacksons Road, is where the original Homestead still resides. This is Jackson Estates oldest vineyard and is planted with Sauvignon Blanc, Chardonnay, Riesling and Pinot Noir.",
    image: "logo-jackson.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:03",
    updated_at: "2018-01-09 21:29:03"
  },
  {
    id: 36,
    name: "Sugarloaf",
    left_pos: "750",
    top_pos: "351",
    styleclass: "img_maps_down",
    description: "The 21 hectare estate vineyard is home to some of the oldest vines in Marlborough, producing stunning Sauvignon Blanc, Chardonnay and Riesling. Founded in 2004 by the then 23 year old Kate Acland.",
    image: "logo-sugarloaf.png",
    on_map: "1",
    label: "1",
    created_at: "2018-01-09 21:29:10",
    updated_at: "2018-01-09 21:29:10"
  }
]

export const wineriesData = wineries.map(w => ({
  id: w.id,
  name: w.name,
  desc: w.description,
  x: +w.left_pos,
  y: +w.top_pos,
  label_direction: w.label === "0" ? "left" : "right",
  image: w.image,
  imgsrc: "", // TODO
  // @if($winery->id == 38){!!'"imgsrc":"/images/indicators/arrow-down.png"'!!} @elseif($winery->id == 19){!!'"imgsrc":"/images/indicators/arrow-up.png"'!!} @else {!!'"imgsrc":" "'!!} @endif,
  type: 'winery',
  labelWidth: 0,
  labelHeight: 0,
  labelX: 0,
  labelY: 0,
}))

export type WineryMapPointData = typeof wineriesData[0];